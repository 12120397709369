import { Card } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import useHasRole from "../../../authorization/hooks/useHasRole";
import VendorSettings from "../../../vendors/components/VendorSettings/VendorSettings";

import UserInfo from "./components/UserInfo";
import UserLanguageSkills from "./components/UserLanguageSkills";
import UserNarratorSettings from "./components/UserNarratorSettings";
import UserOrganizations from "./components/UserOrganizations";
import UserPermissions from "./components/UserPermissions";
import UserProducersAdmin from "./components/UserProducersAdmin";
import UserStorytoolArchiveSettings from "./components/UserStorytoolArchiveSettings";
import UserVendors from "./components/UserVendors";

export default function SuperAdminUserView({ id }) {
	const hasRole = useHasRole();

	const [user, loading, error] = useDocumentData(id && db.collection("users").doc(id));

	return (
		<LoadingContext loading={loading} error={error} data={user}>
			{user && (
				<Card.Group>
					<UserInfo user={user} />
					<UserVendors user={user} />
					{hasRole("owner") && <UserPermissions user={user} />}
					<UserStorytoolArchiveSettings user={user} />
					{hasRole("owner") && user.permissions?.role === "admin" && <UserProducersAdmin user={user} />}
					<UserNarratorSettings user={user} />
					<UserLanguageSkills user={user} />
					<VendorSettings type="user" uid={user.id} currentVendors={user.vendors} />
					<UserOrganizations user={user} />
				</Card.Group>
			)}
		</LoadingContext>
	);
}
