import { Button, Card } from "semantic-ui-react";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import { updateDocument } from "astrid-firestore/src/helpers";

import Form from "../../../../forms/components/Form/Form";
import useForm from "../../../../forms/hooks/useForm";
import Select from "../../../../ui/components/Select/Select";

import useImprintNames from "../hooks/useImprintNames";

export default function UserStorytoolArchiveSettings({ user }) {
	const { t } = useTranslation();

	const [imprintNames, loading, error] = useImprintNames();

	const form = useForm({
		defaultValues: {
			enableArchiveAccess: !!user?.permissions?.storytoolArchive?.enableArchiveAccess,
			accessToAllImprintNames: !!user?.permissions?.storytoolArchive?.accessToAllImprintNames,
			imprintNames: user?.permissions?.storytoolArchive?.imprintNames || [],
		},
		values: {
			enableArchiveAccess: !!user?.permissions?.storytoolArchive?.enableArchiveAccess,
			accessToAllImprintNames: !!user?.permissions?.storytoolArchive?.accessToAllImprintNames,
			imprintNames: user?.permissions?.storytoolArchive?.imprintNames || [],
		},
	});

	const {
		handleSubmit,
		watch,
		setValue,
		formState: { isSubmitting, dirtyFields, errors },
	} = form;

	const [enableArchiveAccess, accessToAllImprintNames] = watch(["enableArchiveAccess", "accessToAllImprintNames"]);

	useEffect(() => {
		if (!enableArchiveAccess) {
			setValue("imprintNames", []);
			setValue("accessToAllImprintNames", false);
		}

		if (accessToAllImprintNames) {
			setValue("imprintNames", []);
		}
	}, [enableArchiveAccess, accessToAllImprintNames, setValue]);

	const submit = handleSubmit(async (formData) => {
		return await updateDocument(db.collection("users").doc(user.id), {
			"permissions.storytoolArchive": {
				enableArchiveAccess: formData?.enableArchiveAccess,
				accessToAllImprintNames: formData?.accessToAllImprintNames,
				imprintNames: formData?.imprintNames,
			},
		});
	});

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("storytoolArchiveSettings", "Storytool archive settings")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Form form={form}>
					<Form.Checkbox
						name="enableArchiveAccess"
						rules={{ required: false }}
						slider
						label={t("enableArchiveAccess", "Enable archive access")}
					/>
					<Form.Checkbox
						name="accessToAllImprintNames"
						rules={{ required: false }}
						slider
						disabled={!enableArchiveAccess}
						label={t("enableAllImprintNames", "Enable all imprint names")}
					/>
					<Form.Field
						name="imprintNames"
						rules={{ required: enableArchiveAccess && !accessToAllImprintNames }}
					>
						<Select
							multiple
							search
							loading={loading}
							disabled={!enableArchiveAccess || accessToAllImprintNames}
							label={t("imprintNames", "Imprint names")}
							options={imprintNames?.map((imprintName) => ({
								key: imprintName,
								value: imprintName,
								text: imprintName,
							}))}
							error={!!errors?.["imprintNames"] || !!error}
						/>
					</Form.Field>
				</Form>
			</Card.Content>
			<Card.Content>
				<Button primary onClick={submit} loading={isSubmitting} disabled={!Object.keys(dirtyFields)?.length}>
					{t("save", "Save")}
				</Button>
			</Card.Content>
		</Card>
	);
}
