import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { useCollectionCount } from "astrid-firestore/src/hooks";

import useHasRight from "../../../authorization/hooks/useHasRight";
import shouldEnableInvoices from "../../../invoices/utils/shouldEnableInvoices";
import useProductionsWithOpenCostsQuery from "../../../productions/hooks/useProductionsWithOpenCostsQuery";

import OrganizationAdminSidebar from "../../components/OrganizationAdminSidebar";

export default function ProducerAdminSidebar() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	const [productionsWithOpenCosts] = useCollectionCount(useProductionsWithOpenCostsQuery({ organization }));

	const options = [
		{
			path: "productions/costs",
			name: t("productions", "Productions"),
			label: productionsWithOpenCosts || undefined,
			hidden: !hasRight("costs.view"),
			options: [
				{
					path: "productions/costs",
					name: t("unfinalized", "Unfinalized"),
					label: productionsWithOpenCosts || undefined,
					hidden: !hasRight("productions.admin"),
				},
			],
		},
		{
			path: "publishers/list",
			name: t("publishers", "Publishers"),
			hidden: !hasRight("publishers.view"),
		},
		{
			path: "users/list",
			name: t("users", "Users"),
			hidden: !hasRight("users.view"),
		},

		{
			path: "readers/list",
			name: t("readers", "Narrators"),
			hidden: !hasRight("users.view"),
		},
		{
			path: "costs/expenses",
			name: t("expenses", "Expenses"),
			hidden: !hasRight("costs.view"),
			options: [
				{
					path: "costs/expenses",
					name: t("unprocessedExpenses", "Unprocessed expenses"),
				},
				{
					path: "orders/purchases",
					name: t("POs"),
					hidden: !hasRight("orders.view"),
				},
				{
					path: "orders/PO",
					hidden: true,
				},
			],
		},
		{
			path: "costs/revenues",
			name: t("revenues", "Revenues"),
			hidden: !hasRight("costs.view"),
			options: [
				{
					path: "costs/revenues",
					name: t("unprocessedRevenues", "Unprocessed revenues"),
				},
				{
					path: "orders/sales",
					name: t("salesOrders", "Sales orders"),
					hidden: !hasRight("orders.view"),
				},
				{
					path: "orders/SO",
					hidden: true,
				},
			],
		},
		shouldEnableInvoices(organization.id) && {
			path: "invoices",
			name: t("invoices", "Invoices"),
			hidden: !hasRight("producerBilling.createPublisherInvoice"),
		},
		{
			path: "prices",
			name: t("globalPrices", "Global prices"),
			hidden: !hasRight("prices.view"),
		},
		{
			path: "agreements/pending",
			name: t("agreements", "Agreements"),
			hidden: !hasRight("agreements.view"),
			options: [
				{
					path: "agreements/pending",
					name: t("pendingAgreements", "Pending agreements"),
				},
				{
					path: "agreements/expiring",
					name: t("expiringAgreements", "Expiring agreements"),
				},
				{
					path: "agreements/templates",
					name: t("agreementTemplates", "Agreement templates"),
				},
				{
					path: "agreements/prepare",
					hidden: !hasRight("agreements.create"),
					name: t("prepareAgreements", "Prepare agreements"),
				},
			],
		},
		{
			path: "studios/gen2",
			name: t("studios", "Studios"),
			options: [
				{
					path: "studios/gen2",
					name: "Gen 2",
				},
				{
					path: "studios/gen1",
					name: "Gen 1",
				},
			],
		},
		{
			path: "settings/language",
			name: t("settings", "Settings"),
			hidden: !hasRight("settings.view"),
			options: [
				{
					path: "settings/language",
					name: t("language", "Language"),
				},
				{
					path: "settings/vendors",
					name: t("vendors", "Vendors"),
				},
				{
					path: "settings/finance",
					name: t("finance", "Finance"),
				},
				{
					path: "settings/studio",
					name: t("studio", "Studio"),
				},
			],
		},
	].filter((v) => v);

	return <OrganizationAdminSidebar options={options} />;
}
