const permissions = {
	publisherAdmin: [
		"admin",
		"createPublisherStaff",
		"viewProductionStatusBasic",
		"createProduction",
		"addScript",
		"bookReader",
		"listPublisherProductions",
		"addLog",
		"commentToReader",
		"commentToAdmin",
		"proofBlurb.edit",
		"proofBlurb.view",
		"productionReaderFee.view",
		"productionBlurbProofScript.view",
		"productionBlurbProofScript.edit",
		"productionReaderFee.edit",
		"productionMissingFields.view",
		"productionScript.upload",
		"productionDeliveryDate.view",
		"production.deleteDraftOffer",
		"productionReaderFilter.view",
		"startPage.listUpcomingDeliveries",
		"readerRegistry.view",
		"readerRegistry.invite",
		"producerBilling.viewPublisherInvoice",
		"production.exportFile",
		"production.masterExportCreate",
		"productions.quickSearch",
		"distribution.sendProductions",
		"productionReaderFee.cancel",
		"finance.acceptTemplate",
		"finance.publisherCosts",
		"list-productions.beta",
		"users.view",
		"users.passwordReset",
		"titles.view",
		"translators.view",
		"series.view",
		"imprints.view",
		"articles.view",
		"authors.view",
		"costs.view",
		"settings.view",
		"orders.view",
		"offers.prepare",
		"productions.manage",
		"productions.readers",
		"organization.todos.view",
		"organization.notifications.view",
	],
	// förlagsmedarbetare
	publisherStaff: [
		"viewProductionStatusBasic",
		"listPublisherProductions",
		"addScript",
		"createProduction",
		"addLog",
		"commentToReader",
		"commentToAdmin",
		"proofBlurb.edit",
		"proofBlurb.view",
		"productionReaderFee.view",
		"productionBlurbProofScript.view",
		"productionBlurbProofScript.edit",
		"productionReaderFee.edit",
		"productionMissingFields.view",
		"productionScript.upload",
		"productionDeliveryDate.view",
		"production.deleteDraftOffer",
		"productions.quickSearch",
		"productionReaderFilter.view",
		"startPage.listUpcomingDeliveries",
		"readerRegistry.view",
		"readerRegistry.invite",
		"producerBilling.viewPublisherInvoice",
		"production.exportFile",
		"production.masterExportCreate",
		"distribution.sendProductions",
		"productionReaderFee.cancel",
		"list-productions.beta",
		"offers.prepare",
		"productions.manage",
		"productions.readers",
		"organization.todos.view",
		"organization.notifications.view",
	],
	producerAdmin: [
		"admin",
		"menuOption.ProducerSettings",
		"createPublisher",
		"createPublisherStaff",
		"createReader",
		"createProducerStaff",
		"createProduction",
		"addScript",
		"approveProduction",
		"bookReader",
		"bookSession",
		"additionalFeaturesCalendar",
		"bookEditing",
		"bookProof",
		"bookSubcontractor",
		"listPublishers",
		"listPublisherProductions",
		"listProducerProductions",
		"viewCalendar",
		"viewLog",
		"addLog",
		"viewProductionStatusBasic",
		"viewProductionStatusAdvanced",
		"viewStaffStatus",
		"viewStaffDetails",
		"commentToReader",
		"commentToSubcontractor",
		"commentToAdmin",
		"commentToPublisher",
		"proofBlurb.edit",
		"proofBlurb.view",
		"productionReader.approve",
		"productionReaderFee.view",
		"productionReaderFee.edit",
		"productionReaderFee.accept",
		"productionBlurbProofScript.view",
		"productionBlurbProofScript.edit",
		"productionMissingFields.view",
		"productionReaderFee.cancel",
		"productionScript.upload",
		"productionStatus.edit",
		"menuOption.Calendar",
		"productionDeliveryDate.view",
		"production.deleteAfterAccepted",
		"production.deleteDraftOffer",
		"productionReaderFilter.view",
		"startPage.listUpcomingDeliveries",
		"readerRegistry.view",
		"readerRegistry.edit",
		"readerRegistry.invite",
		"userProfile.edit",
		"producerBilling.createPublisherInvoice",
		"startPage.listAdminAlerts",
		"producerBilling.viewPublisherInvoice",
		"studioSession.connect",
		"availability.editReaders",
		"availability.editOthers",
		"production.exportFile",
		"production.masterExportEdit",
		"production.masterExportCreate",
		"distribution.sendProductions",
		"distribution.editDistributors",
		"productions.listUnacceptedProductions",
		"productions.quickSearch",
		"finance.createPurchaseOrder",
		"finance.editProductionPrices",
		"finance.editProducerPrices",
		"finance.listPurchaseOrders",
		"finance.publisherCosts",
		"finance.unpersonalCosts",
		"agreements.view",
		"agreements.create",
		"agreements.delete",
		"agreements.send",
		"agreements.terminate",
		"prices.view",
		"prices.create",
		"prices.delete",
		"prices.apply",
		"orders.view",
		"orders.create",
		"orders.delete",
		"costs.view",
		"costs.delete",
		"users.view",
		"titles.view",
		"translators.view",
		"series.view",
		"imprints.view",
		"articles.view",
		"authors.view",
		"publishers.view",
		"settings.view",
		"studios.view",
		"viewProductionStatistics",
		"list-productions.beta",
		"documents.view",
		"documents.create",
		"myProductions.productionRequests",
		"production.accept",
		"offers.send",
		"offers.prepare",
		"users.viewContactDetails",
		"users.passwordReset",
		"productions.admin",
		"productions.manage",
		"productions.team",
		"productions.finalize",
		"productions.readers",
		"organization.todos.view",
		"organization.notifications.view",
		"apps.view",
		"todos.delete",
		"calendar.editAllSessions",
		"calendar.viewTimeline",
		"calendar.defaultTimeline",
		"calendar.viewAllSessions",
		"calendar.defaultAllSessions",
	],
	// production coordinators
	producerStaff: [
		"viewProductionStatusAdvanced",
		"listProducerProductions",
		"viewCalendar",
		"viewLog",
		"addLog",
		"createProduction",
		"createProducerStaff",
		"commentToReader",
		"commentToSubcontractor",
		"commentToAdmin",
		"commentToPublisher",
		"proofBlurb.edit",
		"proofBlurb.view",
		"productionReader.approve",
		"productionReaderFee.view",
		"productionReaderFee.edit",
		"productionReaderFee.accept",
		"productionReaderFee.cancel",
		"productions.quickSearch",
		"productionBlurbProofScript.view",
		"productionBlurbProofScript.edit",
		"productionMissingFields.view",
		"productionScript.upload",
		"productionStatus.edit",
		"menuOption.Calendar",
		"productionDeliveryDate.view",
		"production.deleteDraftOffer",
		"productionReaderFilter.view",
		"startPage.listUpcomingDeliveries",
		"readerRegistry.view",
		"readerRegistry.edit",
		"readerRegistry.invite",
		"producerBilling.createPublisherInvoice",
		"startPage.listAdminAlerts",
		"producerBilling.viewPublisherInvoice",
		"production.exportFile",
		"productions.listUnacceptedProductions",
		"userProfile.edit",
		"viewStaffDetails",
		"viewStaffStatus",
		"availability.editReaders",
		"availability.editOthers",
		"bookSession",
		"additionalFeaturesCalendar",
		"bookEditing",
		"bookProof",
		"bookSubcontractor",
		"finance.acceptTemplate",
		"production.masterExportCreate",
		"list-productions.beta",
		"documents.view",
		"offers.send",
		"offers.prepare",
		"users.viewContactDetails",
		"productions.manage",
		"productions.finalize",
		"productions.team",
		"productions.readers",
		"calendar.editOwnSessions",
		"calendar.viewTimeline",
		"calendar.defaultTimeline",
		"calendar.viewAllSessions",
	],
	producerRecorder: [
		"viewProductionStatusAdvanced",
		"addScript",
		"viewCalendar",
		"additionalFeaturesCalendar",
		"listProducerProductions",
		"viewLog",
		"addLog",
		"commentToReader",
		"commentToPublisher",
		"proofBlurb.view",
		"productionBlurbProofScript.view",
		"productionMissingFields.view",
		"menuOption.Calendar",
		"productionReaderFilter.view",
		"productionStatus.edit",
		"startPage.listProductionsMissingRecorder",
		"studioSession.connect",
		"availability.standardTime",
		"availability.timeOff",
		"availability.editReaders",
		"productions.listUnacceptedProductions",
		"finance.acceptTemplate",
		"finance.personalCosts",
		"documents.view",
		"calendar.viewTimeline",
		"calendar.gridView",
		"calendar.viewAllSessions",
	],
	producerEditor: [
		"viewProductionStatusAdvanced",
		"bookEditing",
		"viewCalendar",
		"listProducerProductions",
		"addLog",
		"viewLog",
		"proofBlurb.view",
		"productionBlurbProofScript.view",
		"productionMissingFields.view",
		"menuOption.Calendar",
		"startPage.listProductionsMissingEditor",
		"productionReaderFilter.view",
		"commentToReader",
		"commentToPublisher",
		"production.masterExportEdit",
		"production.masterExportCreate",
		"distribution.sendProductions",
		"finance.acceptTemplate",
		"finance.personalCosts",
		"documents.view",
	],
	producerProofer: [
		"viewProductionStatusAdvanced",
		"bookProof",
		"viewCalendar",
		"listProducerProductions",
		"viewLog",
		"addLog",
		"proofBlurb.view",
		"productionBlurbProofScript.view",
		"productionMissingFields.view",
		"menuOption.Calendar",
		"startPage.listProductionsMissingProofer",
		"productionReaderFilter.view",
		"commentToReader",
		"finance.acceptTemplate",
		"finance.personalCosts",
		"documents.view",
		"calendar.gridView",
	],
	producerSubcontractor: [
		"subcontractor",
		"viewProductionStatusAdvanced",
		"bookSubcontractor",
		"listProducerProductions",
		"viewLog",
		"addLog",
		"productionBlurbProofScript.view",
		"productionMissingFields.view",
		"productionReaderFilter.view",
		"finance.acceptTemplate",
		"finance.personalCosts",
		"documents.view",
		"productions.manage",
	],
	reader: [
		"viewProductionStatusAdvanced",
		"viewCalendar",
		"addLog",
		"viewLog",
		"listProducerProductions",
		"commentToPublisher",
		"proofBlurb.view",
		"productionReader.approve",
		"productionReaderFee.view",
		"productionReaderFee.accept",
		"productionBlurbProofScript.view",
		"menuOption.Calendar",
		"availability.bookableTime",
	],
	// manager: [
	// 	"productionBlurbProofScript.view",
	// 	"productionBlurbProofScript.edit",
	// 	"productionScript.upload",
	// 	"productionStatus.edit",
	// 	"productionMissingFields.view",
	// 	"menuOption.Calendar",
	// 	"productionReaderFilter.view",
	// 	"readerRegistry.view",
	// 	"distribution.sendProductions",
	// ],
	finance: ["finance.createPurchaseOrder", "finance.listPurchaseOrders"],
	super: ["super", "production.deleteAfterAccepted"],
	producerReaderManager: [
		"bookReader",
		"readerRegistry.view",
		"readerRegistry.invite",
		"readerRegistry.campaign",
		"userProfile.edit",
		"commentToReader",
		"commentToSubcontractor",
		"availability.editReaders",
		"documents.view",
	],
};

export default permissions;
